import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxHeight: "100px",
    padding: "10px 20px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "16px",
    },
  },
  containerInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: "clamp(16px, 2vw, 24px)",
    fontWeight: 400,
    color: "#595959",
  },
  logo: {
    height: "clamp(40px, 8vw, 60px)",
    width: "auto",
    imageRendering: "auto",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "16px",
    },
  },
  info: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "16px",
    borderLeft: "1px solid #E5E7EB",
    paddingLeft: "16px",
  },
  iconAndTitle: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "8px",
  },
  icon: {
    color: "#595959",
  },
  local: {
    fontWeight: "bold",
     fontSize: "clamp(18px, 2vw, 22px)",
  },
}));

export default useStyles;
