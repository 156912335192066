import { Box, Typography } from "@material-ui/core";
import { AccessTime, LocationOn } from "@material-ui/icons";
import { useEffect, useState } from "react";
import useStyles from "./style";

const Header = () => {
  const classes = useStyles();
  const unitData = localStorage.getItem("gov_ticket_office");
  const unidade = unitData ? JSON.parse(unitData) : null;
  const [horaAtual, setHoraAtual] = useState(new Date());

  useEffect(() => {
    const intervalo = setInterval(() => {
      setHoraAtual(new Date());
    }, 1000);

    return () => clearInterval(intervalo);
  }, []);

  const formatarDataHora = (data) => {
    const hora = new Intl.DateTimeFormat("pt-BR", {
      timeZone: "America/Campo_Grande",
      hour: "2-digit",
      minute: "2-digit",
    }).format(data);

    const dataFormatada = new Intl.DateTimeFormat("pt-BR", {
      timeZone: "America/Campo_Grande",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(data);

    return `${dataFormatada} - ${hora}`;
  };

  return (
    <Box className={classes.header}>
      <img
        src="/assets/images/logo_panel.png"
        alt="Logo Governo"
        className={classes.logo}
      />
      <Box className={classes.containerInfo}>
        <Box className={classes.info}>
          <LocationOn className={classes.icon} fontSize="small" />
          <Box className={classes.iconAndTitle}>
            <Typography className={classes.title}>Local de atendimento</Typography>
            <Typography className={classes.local}>
              {unidade?.setor
                ? unidade?.setor?.unidade?.nome
                : unidade?.unidade?.nome}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.info}>
          <AccessTime className={classes.icon} fontSize="small" />
          <Box className={classes.iconAndTitle}>
            <Typography className={classes.title}>Data/hora</Typography>
            <Typography className={classes.local}>
              {formatarDataHora(horaAtual)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
