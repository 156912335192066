import { Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    maxHeight: `calc(100vh - 100px)`,
    border: '1px solid #FFF',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  previous: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  current: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 'clamp(16px, 2vw, 24px)',
  },
  tableContainer: {
    flex: 1,
    overflowY: 'auto',
    maxHeight: '100%',
  },
  table: {
    minWidth: 300,
    tableLayout: 'fixed',
    height: '100%',
    width: '100%',
  },
  tableHead: {
    backgroundColor: '#F3F4F6',
  },
  tableHeaderCell: {
    color: '#595959',
    textTransform: 'uppercase',
    padding: '16px 0px',
    fontWeight: 500,
    textAlign: 'center',
    fontSize: 'clamp(14px, 1.5vw, 20px)',
    position: 'relative',
    '&:not(:last-child)::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      height: 16,
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  tableBody: {},
  tableRow: {
  },
  tableCell: {
    textAlign: 'center',
    borderBottom: 'none',
    padding: '16px 0px',
    verticalAlign: 'middle',
    position: 'relative',
    '&:not(:last-child)::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      height: 16,
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  cellText: {
    fontSize: 'clamp(18px, 2vw, 28px)',
    fontWeight: 600,
    color: '#212121',
  },
  skeleton: {
    width: '80%',
    height: '100%',
    margin: '0 auto',
  },
}));

export default useStyles;
