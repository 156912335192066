import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  containerPanel: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  playCurrentInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 24px',
    gap: '6px',
    borderRadius: '30px',
    marginBottom: '56px',
    fontWeight: 700,
    backgroundColor: '#FFF',
    color: '#004F9F',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
      marginBottom: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '6px 12px',
      fontSize: 'clamp(12px, 2vw, 16px)',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '16px 32px',
      fontSize: 'clamp(20px, 3vw, 24px)',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: '#004F9F',
    borderRight: '1px solid #E5E7EB',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 'clamp(18px, 2vw, 24px)',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 'clamp(24px, 3vw, 32px)',
    },
  },
  currentInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#FFF',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    padding: 0,
    transition: 'transform 0.5s ease-in-out, min-height 0.5s ease-in-out',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '15px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '30px',
    },
  },
  zoomEffect: {
    transform: 'scale(1.05)',
    minHeight: '50vh',
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(1)',
      minHeight: 'auto',
    },
  },
  currentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '24px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px',
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: '32px',
    },
  },
  currentLabel: {
    fontWeight: 'normal',
    fontSize: 'clamp(3rem, 2.5vw, 3rem)', 
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'clamp(1.5rem, 3vw, 2rem)',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 'clamp(4rem, 3vw, 5rem)',
    },
  },
  currentNumber: {
    fontSize: 'clamp(4rem, 6vw, 8rem)',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 'clamp(4rem, 5vw, 8rem)',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 'clamp(8rem, 8vw, 10rem)',
    },
  },
  noInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '700px',
    height: '100%',
    textAlign: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  noInfoImage: {
    width: '160px',
    height: '160px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    color: '#004F9F',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '120px',
      height: '120px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      height: '100px',
    },
  },
  noInfo: {
    fontWeight: 400,
    textTransform: 'uppercase',
    marginTop: '24px',
    color: '#FFF',
    textAlign: 'center',
    fontSize: 'clamp(1rem, 2.5vw, 2rem)',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 'clamp(2rem, 3vw, 3rem)',
    },
  },
}));

export default useStyles;
