import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Fullscreen, FullscreenExit } from "@material-ui/icons";
import { useEffect, useState } from "react";
import Header from "./components/Header";
import ServicePanelInfo from "./components/panel";
import { QueueManagementProvider } from "./context/serviceLocationContext";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  fullscreenButton: {
    position: 'fixed',
    bottom: '30px',
    right: '20px',
    zIndex: 1000,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    opacity: 0,
    visibility: 'hidden',
    transform: 'translateY(-10px)',
    transition: 'opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  fullscreenButtonVisible: {
    opacity: 1,
    visibility: 'visible',
    transform: 'translateY(0)',
  },
}));

const ServicePanel = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const classes = useStyles();
  let hideButtonTimeout;

  const handleCancelFullscreen = () => setShowModal(false);

  const handleFullscreenToggle = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen?.();
      handleCancelFullscreen()
    } else {
      document.exitFullscreen?.();
    }
    setIsFullscreen(!isFullscreen);
  };

  const handleMouseMove = () => {
    if (!showButton) setShowButton(true);

    clearTimeout(hideButtonTimeout);

    hideButtonTimeout = setTimeout(() => {
      setShowButton(false);
    }, 1000);
  };

  useEffect(() => {
    const handleFullscreenChange = () => setIsFullscreen(!!document.fullscreenElement);

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(hideButtonTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QueueManagementProvider>
      <Box className={classes.root}>
        <IconButton
          className={`${classes.fullscreenButton} ${showButton ? classes.fullscreenButtonVisible : ''}`}
          onClick={handleFullscreenToggle}
        >
          {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
        </IconButton>
        <Header />
        <Box style={{ flex: 1, overflow: 'hidden' }}>
          <ServicePanelInfo />
        </Box>
        <Dialog
          open={showModal}
          onClose={handleCancelFullscreen}
        >
          <DialogTitle>Ativar Tela Cheia</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Para uma melhor experiência no painel de atendimentos, recomendamos ativar o modo de <b>tela cheia</b>. Deseja continuar?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelFullscreen} color="secondary">
              Não, Obrigado
            </Button>
            <Button onClick={handleFullscreenToggle} color="primary" autoFocus>
              Sim, Ativar Tela Cheia
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </QueueManagementProvider>
  );
};

export default ServicePanel;
