import { Box, Typography } from '@material-ui/core';
import { PauseCircleFilled, PlayCircleFilled } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { useServiceLocationContext } from '../../hooks/useServiceLocationContext';
import { useStyles } from './style';

export const CustomerCall = (): JSX.Element => {
  const classes = useStyles();
  const { currentTicket } = useServiceLocationContext();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const synthesisRef = useRef<SpeechSynthesis | null>(null);
  const utteranceRef = useRef<SpeechSynthesisUtterance | null>(null);

  useEffect(() => {
    if (currentTicket) {
      speakTicket(currentTicket);
    } else {
      stopSpeaking();
    }

    return () => {
      stopSpeaking();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTicket]);

  const speakTicket = (ticket: { senha: string; guiche: string }) => {
    if ('speechSynthesis' in window) {
      const synthesis = window.speechSynthesis;
      synthesisRef.current = synthesis;

      const utterance = new SpeechSynthesisUtterance(
        `Atendimento número ${ticket.senha}. Por favor, dirija-se ao guichê ${ticket.guiche}.`
      );
      utteranceRef.current = utterance;

      utterance.onstart = () => {
        setIsSpeaking(true);
      };

      utterance.onend = () => {
        setIsSpeaking(false);
      };

      synthesis.speak(utterance);
    } else {
      console.log('A funcionalidade de síntese de voz não é suportada neste dispositivo.');
    }
  };

  const stopSpeaking = () => {
    if (synthesisRef.current && synthesisRef.current.speaking) {
      synthesisRef.current.cancel();
    }
    setIsSpeaking(false);
  };

  const handleIconClick = () => {
    if (!synthesisRef.current || !utteranceRef.current) return;

    if (synthesisRef.current.paused) {
      synthesisRef.current.resume();
      setIsSpeaking(true);
    } else if (synthesisRef.current.speaking) {
      synthesisRef.current.pause();
      setIsSpeaking(false);
    }
  };

  const formatGuiche = (guiche: number) => (guiche < 10 ? `0${guiche}` : guiche);

  return (
    <Box className={classes.container}>
      <Box
        className={`${classes.currentInfo} ${isSpeaking ? classes.zoomEffect : ''}`}
      >
        {currentTicket ? (
          <>
            <Box
              className={classes.playCurrentInfo}
              onClick={handleIconClick}
              aria-label={isSpeaking ? 'Pausar atendimento' : 'Iniciar atendimento'}
            >
              {isSpeaking ? <PauseCircleFilled /> : <PlayCircleFilled />} Senha em Atendimento
            </Box>
            <Box className={classes.currentBox}>
              <Typography variant="h4" className={classes.currentLabel}>Senha</Typography>
              <Typography variant="h3" className={classes.currentNumber}>
                {currentTicket?.senha || 'N/A'}
              </Typography>
            </Box>
            <Box className={classes.currentBox}>
              <Typography variant="h4" className={classes.currentLabel}>GUICHÊ</Typography>
              <Typography variant="h3" className={classes.currentNumber}>
                {currentTicket?.guiche ? formatGuiche(+currentTicket.guiche) : 'N/A'}
              </Typography>
            </Box>
          </>
        ) : (
          <Box className={classes.noInfoContainer}>
            <div className={classes.noInfoImage}>
              <img src="/assets/images/television_off.svg" alt="Televisão Desligada" style={{ width: '80%', height: '80%' }} />
            </div>
            <Typography variant="h3" className={classes.noInfo}>
              Nenhuma senha está sendo atendida no momento
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomerCall;
