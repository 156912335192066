import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useServiceLocationContext } from "../../hooks/useServiceLocationContext";
import useStyles from './style';
export const CallPanelPreviousList = (): JSX.Element => {
  const classes = useStyles();
  const { recentTickets, loadingRecentTickets: loading } = useServiceLocationContext();

  const maxRows = 10;

  const tickets = recentTickets.slice(1, 1 + maxRows);

  const emptyRows = maxRows - (loading ? 0 : tickets.length);

  return (
    <div className={classes.main}>
      <div className={classes.previous}>
        <div className={classes.current}>
          <Typography variant="h6" className={classes.label}>Senhas Anteriores</Typography>
        </div>
        <TableContainer
          component={Paper}
          className={classes.tableContainer}
          style={{
            boxShadow: 'none',
            backgroundColor: 'transparent',
          }}
        >
          <Table
            className={classes.table}
            aria-label="Senhas Anteriores"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>Senha</TableCell>
                <TableCell className={classes.tableHeaderCell}>Guichê</TableCell>
                <TableCell className={classes.tableHeaderCell}>Horário</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {loading
                ? Array.from({ length: maxRows }).map((_, index) => (
                  <TableRow
                    key={`skeleton-${index}`}
                    className={classes.tableRow}
                  >
                    <TableCell className={classes.tableCell}>
                      <Skeleton className={classes.skeleton} />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Skeleton className={classes.skeleton} />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Skeleton className={classes.skeleton} />
                    </TableCell>
                  </TableRow>
                ))
                : (
                  <>
                    {tickets.map((ticket, index) => (
                      <TableRow
                        key={`ticket-${index}`}
                        className={classes.tableRow}
                      >
                        <TableCell className={classes.tableCell}>
                          <Typography className={classes.cellText}>
                            {ticket.senha}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Typography className={classes.cellText}>
                            {ticket.guiche}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Typography className={classes.cellText}>
                            {ticket.horario}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && Array.from({ length: emptyRows }).map((_, index) => (
                      <TableRow
                        key={`empty-${index}`}
                        className={classes.tableRow}
                      >
                        <TableCell className={classes.tableCell}>
                          <Typography className={classes.cellText}>&nbsp;</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Typography className={classes.cellText}>&nbsp;</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Typography className={classes.cellText}>&nbsp;</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
