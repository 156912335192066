import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  customerCall: {
    flex: '0 0 70%',
    transition: 'flex 0.3s ease-in-out, opacity 0.3s ease-in-out',
    opacity: 1,
    padding: 0,
    visibility: 'visible',
    [theme.breakpoints.down('sm')]: {
      flex: 'none',
      width: '100%',
      height: 'auto',
    },
  },
  callPanelPreviousList: {
    flex: '0 0 30%',
    height: '100%',
    transition: 'flex 0.3s ease-in-out, opacity 0.3s ease-in-out',
    opacity: 1,
    visibility: 'visible',
    '&.hidden': {
      opacity: 0,
      visibility: 'hidden',
      flex: '0 0 0',
    },
    [theme.breakpoints.down('sm')]: {
      flex: 'none',
      width: '100%',
      height: 'auto',
    },
  },
}));

export default useStyles;
