import { Box } from '@material-ui/core';
import { CallPanelPreviousList } from '../callPanelPreviousList';
import { CustomerCall } from '../customerCall';
import useStyles from './style';

const ServicePanelInfo = () => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Box className={classes.customerCall}>
        <CustomerCall />
      </Box>
      <Box className={classes.callPanelPreviousList}>
        <CallPanelPreviousList />
      </Box>
    </Box>
  );
};

export default ServicePanelInfo;
